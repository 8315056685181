import '../styles/globals.css'
import type { AppProps } from 'next/app';
import Script from 'next/script';


function MyApp({ Component, pageProps }: AppProps) {

  return (
    <>
      <Script
        id="crisp"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="a244c712-2218-4fa6-8956-134c9eb40094";(function(){d = document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.defer=1;d.getElementsByTagName("head")[0].appendChild(s);})();
          `,
        }}
      />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
